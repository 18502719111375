<template>
  <div class="p-4">
    <numbers-total class="m-4 p-4 bg-dark-jungle rounded" />
    <numbers-today class="m-4 p-4 bg-dark-jungle rounded" />
    <logs class="m-4 p-4 bg-dark-jungle rounded" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { FETCH, LISTEN } from "@/store/actions";

import NumbersTotal from "./Total";
import NumbersToday from "./Today";
import Logs from "./Logs/";

export default {
  name: "AdminDashboard",
  components: { NumbersTotal, NumbersToday, Logs },
  mounted() {
    this.fetchAnalytics();
    this.listenAnalytics();

    this.fetchEvents();
    this.listenEvents();
  },
  methods: {
    ...mapActions("adminEvents", { fetchEvents: FETCH, listenEvents: LISTEN }),
    ...mapActions("adminAnalytics", { fetchAnalytics: FETCH, listenAnalytics: LISTEN }),
  },
};
</script>

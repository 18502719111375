/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
import { ACTIONS_FILES, STYLES_PROMPTS } from "@/constants/ai";

import { toSlug, getNumberStartingEntries } from "@/helpers/utils";
import { toCapitalizeAllWords } from "@/helpers/strings";

export const countTokens = (prompt) => {
  if (!prompt) return 0;

  // Split prompt into individual words
  const words = prompt.split(" ");

  // Count the number of tokens in the prompt
  let tokenCount = 0;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    // Check if word is a special token that counts as multiple tokens
    if (word.startsWith("<") && word.endsWith(">")) {
      const token = word.substring(1, word.length - 1);
      if (["BOS", "EOS", "UNK", "PAD", "MASK"].includes(token)) {
        tokenCount += 1;
      } else if (token.startsWith("CTRL")) {
        const num = parseInt(token.substring(4));
        tokenCount += num;
      }
    } else {
      tokenCount += 1;
    }
  }

  return tokenCount;
};

export const sanitizeTextWithContext = (text, context, content = null) => {
  Object.entries(context).forEach(([key, value]) => {
    text = text.replaceAll(`$${key}`, value);
  });

  if (content)
    Object.entries(content).forEach(([key, value]) => {
      text = text.replaceAll(`$${key}`, value);
    });

  return text;
};

export const sanitizeSubtasks = ({ quillId, fileId, advancedSubtasks, isFree = false }) => {
  const result = [{ fileId }];

  advancedSubtasks.forEach((s, index) => {
    const {
      name: commandName,
      prompt: commandPrompt = null,
      model: commandModel = null,
      conditionRegex: commandConditionRegex = null,
    } = s;

    const commandKey = toSlug(commandName);

    if (quillId) result[0].quillId = quillId;
    if (isFree) result[0].isFree = true;
    if (commandPrompt) result[0][`prompt_${commandKey}`] = commandPrompt;
    if (commandModel) result[0][`model_${commandKey}`] = commandModel;
    if (commandConditionRegex) result[0][`condition-regex_${commandKey}`] = commandConditionRegex;
    result[0][`${index + 1}_${commandKey}`] = ACTIONS_FILES.WAITING;
  });

  return result;
};

export const createSubtaskRewrite = ({ projectSettings, tasks }) => {
  const { quillId, styleId } = projectSettings;

  if (!styleId || styleId === "vanilla-verve") return {};

  const style = STYLES_PROMPTS.find(({ id }) => id === styleId);

  const tasksWithNumber = Object.keys(getNumberStartingEntries(tasks));

  const index = tasksWithNumber.length + 1;
  const key = "rewrite-spell";
  const language = "en";

  const lastTask = tasksWithNumber[tasksWithNumber.length - 1];

  const [_, lastTaskKey] = lastTask.split("_");

  let lastTaskName = lastTaskKey.replaceAll("-", " ");

  lastTaskName = toCapitalizeAllWords(lastTaskName);
  lastTaskName = lastTaskName.replaceAll(" ", "");

  /* ${style.prompt[language]} */
  /*
  - Use a first-person perspective to create a personal connection with the reader.
- Introduce with Personal Touch: Start from a personal viewpoint or experience to immediately engage the reader.
- Descriptive Language: Use vivid details to paint a clear picture. Make the reader "see" what you're discussing.
- Question the Reader: Insert a rhetorical question or two, pushing them to think and anticipate where the narrative might head.
- Engage Conversationally: Weave in casual, conversational elements to maintain relatability and approachability in tone.
- Play with Contrast: Bring in two opposing elements or experiences, making the narrative dynamic and thought-provoking.
- Emotional Connection: Dive deep into emotions, be it excitement, nostalgia, curiosity, or any other emotion relevant to the topic.
- Break & Structure: Use short sections or paragraphs to keep readers engaged. Each section should unfold a new aspect or perspective of the main theme.
- Mix Sentence Length: Use a blend of short impactful sentences and longer, flowing ones for rhythm and pacing.
- Make the text more human.
 */
  const prompt = `WRITING STYLE:
${style.prompt[language]}

TEXT:
{spell${lastTaskName}}

YOUR TEXT:
`;

  return {
    [`${index}_${key}`]: ACTIONS_FILES.WAITING,
    [`prompt_${key}`]: prompt,
    [`model_${key}`]: "quill-1-global",
  };
};

export const chaptersArrayToString = (array, isAddingFiles = true) => {
  if (!Array.isArray(array)) {
    console.log(`Invalid argument: Expected an array.`);
    return "";
  }

  let result = "";

  array.forEach((item, index) => {
    result += `${item.name}\n`;
    result += `${item.description}\n`;
    if (isAddingFiles && Array.isArray(item.children)) result += "Subchapters:\n";

    if (!Array.isArray(item.children)) {
      console.log(`Invalid data at index ${index}: Expected 'children' to be an array.`);
      return "";
    }

    if (isAddingFiles)
      item.children.forEach((element, elementIndex) => {
        result += `  ${elementIndex + 1}. ${element.name}\n`;
        result += `     ${element.description}\n`;
      });

    if (index !== array.length - 1) {
      result += "\n";
    }
  });

  return result;
};

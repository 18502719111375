<template>
  <div>
    <div class="p-4 bg-dark rounded-lg">
      <p class="mb-0 text-uppercase font-weight-bold">Message</p>
      <div>
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GET } from "@/store/actions";

export default {
  name: "LogMessage",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    content: "",

    isLoading: true,
  }),
  mounted() {
    this.triggerGetMessage();
  },
  methods: {
    ...mapActions("projectsTasksDiscussionsMessages", { getMessage: GET }),
    async triggerGetMessage() {
      this.isLoading = true;

      const { projectId, taskId, messageId } = this.data;
      const result = await this.getMessage({ projectId, taskId, messageId });

      this.content = result?.content;

      this.isLoading = false;
    },
  },
};
</script>

<style></style>

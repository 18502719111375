<template>
  <div>
    <h3 class="text-uppercase">Today</h3>

    <b-row no-gutters>
      <b-col>
        <p class="mb-0 ">
          <small>Users</small>
        </p>
        <strong>{{ today.user?.create }}</strong>
      </b-col>

      <b-col>
        <p class="mb-0 ">
          <small>Messages Exchanged</small>
        </p>
        <strong>{{ today.messageAgent?.create + today.messageUser?.create || 0 }}</strong>
      </b-col>

      <b-col>
        <p class="mb-0 ">
          <small>Credits Used</small>
        </p>
        <strong>{{ today.credits?.create || 0 }}</strong>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BRow, BCol } from "bootstrap-vue";

export default {
  name: "Today",
  components: { BRow, BCol },
  computed: {
    ...mapState({
      today: (state) => state.adminAnalytics.today,
    }),
  },
};
</script>

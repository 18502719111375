<template>
  <div>
    <h3 class="text-uppercase">Total</h3>
    <b-row no-gutters>
      <b-col>
        <p class="mb-0 ">
          <small>Users</small>
        </p>
        <strong>{{ total.user?.create }}</strong>
      </b-col>

      <b-col>
        <p class="mb-0 ">
          <small>Messages Exchanged</small>
        </p>
        <strong>{{ total.messageAgent?.create + total.messageUser?.create || 0 }}</strong>
      </b-col>

      <b-col>
        <p class="mb-0 ">
          <small>Credits</small>
        </p>
        <strong>{{ total.credits?.create || 0 }}</strong>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BRow, BCol } from "bootstrap-vue";

export default {
  name: "Today",
  components: { BRow, BCol },
  computed: {
    ...mapState({
      total: (state) => state.adminAnalytics.total,
    }),
  },
};
</script>

export const FEED_MESSAGES = {
  ["user-create"]: "A new user has registered: <strong>$email</strong>",
  ["user-update"]: "A user has updated: <strong>$email</strong>",
  ["user-remove"]: "A user has left: <strong>$email</strong>",
  ["project-create"]: "A new space has been created: <strong>$name</strong> by $uid",
  ["project-update"]: "A space has been updated: <strong>$name</strong> by $uid",
  ["project-remove"]: "A space has been removed: <strong>$name</strong> by $uid",
  ["document-create"]: "A new $documentType has been created: <strong>$name</strong> by $uid",
  ["document-update"]: "A $documentType has been updated: <strong>$name</strong> by $uid",
  ["document-remove"]: "A $documentType has been removed: <str ong>$name</strong> by $uid",
  ["task-create"]: "A new $actionId task has been created by $uid",
  ["task-update"]: "A task has been updated by $uid",
  ["task-remove"]: "A task has been removed by $uid",
  ["messageAgent-create"]: "Louis AI has sent a message to $uid",
  ["messageAgent-remove"]: "Louis AI message has been removed by $uid",
  ["messageUser-create"]: "$uid has sent a message to Louis AI",
  ["messageUser-remove"]: "$uid has removed a message to Louis AI",
  ["serviceOpenAI-create"]: "A request to OpenAI has been made ($credits credits)",
  ["serviceAWSTranscribe-create"]: "A request to AWS Transcribe has been made ($credits credits)",
  ["serviceWebscraping-create"]: "A request to Webscrapping has been made ($credits credits)",
};

<template>
  <div>
    <h3 class="text-uppercase">Logs</h3>

    <b-row v-for="i in itemsSorted" :key="i.id" class="mt-3 border-bottom">
      <b-col>
        <log :data="i" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BRow, BCol } from "bootstrap-vue";

import Log from "./Log";

export default {
  name: "Logs",
  components: { BRow, BCol, Log },
  computed: {
    ...mapGetters("adminEvents", ["itemsSorted"]),
  },
};
</script>

<style></style>

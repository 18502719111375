const TYPES = {
  USER: "user",
  PROJECT: "project",
  STORAGE: "storage",
  DOCUMENT: "document",
  EMAIL: "email",
  TASK: "task",
  CREDITS: "credits",
  MESSAGE_USER: "messageUser",
  MESSAGE_AGENT: "messageAgent",
  GPT: "gpt",
  DALL_E: "dalle",
  WORDS: "words",
  IMAGES: "images",
  MINUTES: "minutes",
  SERVICE_OPENAI: "serviceOpenAI",
  SERVICE_WEBSCRAPING: "serviceWebscraping",
  SERVICE_AWSTRANSCRIBE: "serviceAWSTranscribe",
};

const TYPES_MONTHLY = [];

const ACTIONS = {
  CREATE: "create",
  UPDATE: "update",
  REMOVE: "remove",
  TOKENS_PROMPT: "tokensPrompt",
  TOKENS_COMPLETION: "tokensCompletion",
  TOKENS_TOTAL: "tokensTotal",
  MINUTES_TRANSCRIPTION: "minutesTranscription",
};

module.exports = {
  TYPES,
  TYPES_MONTHLY,
  ACTIONS,
};

<template>
  <div>
    <div class="code-block rounded pt-3 pl-4 pr-4">
      <p class="mb-0 text-uppercase font-weight-bold">ID</p>
      <pre><code>{{ data.response.id}}</code></pre>
    </div>

    <div class="mt-4 code-block rounded pt-3 pl-4 pr-4">
      <p class="mb-0 text-uppercase font-weight-bold">Model</p>
      <pre><code>{{ data.response.model}}</code></pre>
    </div>

    <div class="mt-4 code-block rounded pt-3 pl-4 pr-4">
      <p class="mb-0 text-uppercase font-weight-bold">Usage</p>
      <pre><code>{{ data.response.usage}}</code></pre>
    </div>

    <div class="mt-4 code-block rounded pt-3 pl-4 pr-4">
      <p class="mb-0 text-uppercase font-weight-bold">Messages</p>
      <pre><code>{{ data.response.messages}}</code></pre>
    </div>

    <div class="mt-4 code-block rounded pt-3 pl-4 pr-4">
      <p class="mb-0 text-uppercase font-weight-bold">Choices</p>
      <pre><code>{{ data.response.choices}}</code></pre>
    </div>

    <div class="mt-4 code-block rounded pt-3 pl-4 pr-4">
      <p class="mb-0 text-uppercase font-weight-bold">Fingerprint</p>
      <pre><code>{{ data.response.system_fingerprint}}</code></pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogOpenai",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<template>
  <div>
    <img
      :src="require(`@/assets/icons/feed/${data.type}-${data.action}.svg`)"
      width="20"
      height="20"
      class="mr-4 d-inline-block"
    />

    <div @click="isOpened = !isOpened" class="d-inline-block cursor-pointer">
      <b-button variant="transparent" class="ml-2 mr-3 p-0">
        <img v-if="isOpened" src="@/assets/icons/arrow-up.svg" width="15" height="15" />
        <img v-else src="@/assets/icons/arrow-down.svg" width="15" height="15" />
      </b-button>

      <p class="d-inline-block" v-html="message"></p>
    </div>

    <div v-if="isOpened" class="mb-4" style="margin-left: 50px">
      <log-openai v-if="data.type === TYPES.SERVICE_OPENAI" :data="data" />
      <log-message v-else-if="data.type === TYPES.MESSAGE_USER || data.type === TYPES.MESSAGE_AGENT" :data="data" />

      <div v-else class="code-block rounded pt-3 pl-4 pr-4">
        <p class="mb-0 text-uppercase font-weight-bold">Data</p>
        <pre><code>{{ data}}</code></pre>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GET } from "@/store/actions";

import { BButton } from "bootstrap-vue";

import { FEED_MESSAGES } from "@/constants/admin";
import { TYPES } from "@/constants/logger";

import { sanitizeTextWithContext } from "@/helpers/ai";

import LogOpenai from "./LogOpenai";
import LogMessage from "./LogMessage";

export default {
  name: "Log",
  components: { BButton, LogOpenai, LogMessage },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    message: null,

    isOpened: false,

    TYPES,
  }),
  mounted() {
    this.getMessage();
  },
  methods: {
    ...mapActions("adminUsers", { getUser: GET }),
    async getMessage() {
      const { uid = "", type, action, ...others } = this.data;
      const name = `${type}-${action}`;

      if (FEED_MESSAGES[name]) {
        let message = FEED_MESSAGES[name];

        if (uid && message.includes("$uid")) {
          const { email, firstName, lastName } = (await this.getUser({ uid })) || {};
          message = message.replace("$uid", firstName ? `${firstName} ${lastName}` : email);
        }

        this.message = sanitizeTextWithContext(message, { ...others });
      } else this.message = `${type} / ${action} / ${uid}`;
    },
  },
};
</script>
